<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">新增单据</span>
      <div class="row">
        <div class="col-md-12">
          <el-row>
            <el-col :span="8">
              <div class="form-group">
                <label><b>分类</b></label><br/>
                <el-cascader :props="{label:'NAME',value:'ID',children:'CHILDREN'}" @change="getStock"
                             v-model="cat.val" :options="cat.ls" placeholder="请选择分类"/>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>物品</b></label><br/>
                <el-select v-model="model.BODY.ST_ID" placeholder="请选择物品" @change="stockChange">
                  <el-option
                      v-for="item in ls_stock"
                      :key="item.ID"
                      :label="item.NAME"
                      :value="item.ID">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>库存量</b></label>
                <div>
                  <el-input v-model="model.STOCK.BALANCE" readonly style="width: 60%;">
                    <template slot="prepend" ><i class="el-icon-coin"/></template>
                  </el-input>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <div class="form-group">
                <label><b>数量</b></label>
                <div>
                  <el-input-number v-model="model.BODY.CNT" :step="1"></el-input-number>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>归属班级</b></label><br />
                <el-select v-model="model.BODY.CLASS_ID">
                  <el-option
                      v-for="item in ls_class"
                      :key="item.ID"
                      :label="item.NAME"
                      :value="item.ID">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>备注</b></label>
                <el-input type="text" v-model="model.BODY.DESCRIPTION" />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data(){
    return{
      sta:{
        show:false,
        loading:false
      },
      model: {STOCK: {},BODY:{}},
      cat:{
        ls:[],
        val:[]
      },
      ls_stock:[],
      ls_class:[],
    }
  },
  methods:{
    init(sn) {
      let self = this;
      this.sta={show:true,loading:false}
      this.model = {STOCK: {}, CONF_SN:sn,BODY:{TYPE: 1, CNT: 1}};
      if (this.cat.ls.length==0){
        this.whale.remote.getResult({
          url: "/api/School/MAT/MCatApi/GetTree",
          completed: function (its) {
            self.cat.ls=its.DATA;
          }
        })
      }
      if (this.ls_class.length == 0) {
        this.whale.remote.getCollection({
          url: "/api/School/ORG/ClassApi/GetList",
          data: {},
          completed: function (its) {
            self.ls_class = its;
          }
        })
      }
    },
    getStock(v){
      let n=v.length;
      if (n>0) this.model.BODY.CAT_ID=v[n-1];
      let self=this;
      this.whale.remote.getCollection({
        url:"/api/School/MAT/StockApi/GetList",
        data: {CAT_ID:this.model.BODY.CAT_ID},
        completed:function (its,n){
          self.model.BODY.ST_ID="";
          self.ls_stock=its;
          if (n==1) self.stockSel(its[0]);
        }
      })
    },
    stockChange(v){
      let self=this;
      this.ls_stock.forEach(it=>{
        if (it.ID==v) self.stockSel(it)
      });
    },
    stockSel(it){
      this.model.STOCK=it;
      this.model.TITLE=it.NAME;
      this.model.BODY.ST_ID=it.ID
      this.model.BODY.ST_NAME=it.NAME
      this.model.BODY.CAT_NAME=it.CAT_NAME
      this.model.BODY.PRICE=it.PRICE
    },
    submit(){
      if (this.model.BODY.CNT>this.model.STOCK.BALANCE){
        this.whale.toast.err("数量不能超过库存量")
        return
      }
      if (this.model.BODY.CLASS_ID!=undefined && this.model.BODY.CLASS_ID!=""){
        for(let i=0;i<this.ls_class.length;i++){
          if (this.model.BODY.CLASS_ID==this.ls_class[i].ID){
            this.model.BODY.CLASS_NAME=this.ls_class[i].NAME;
          }
        }
      }
      this.model.DESCRIPTION=this.model.BODY.CNT+"("+this.model.STOCK.FUNIT+")"
      let self=this;
      self.sta.loading=true;
      this.whale.remote.getResult({
        url:"/api/School/WFL/ActApi/Save",
        data:self.model,
        finally(){self.sta.loading=false;},
        completed:function (){
          self.sta.show=false;
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
